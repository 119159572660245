// src/LegionsOfHeimland.js
import React from 'react';
import { Link } from 'react-router-dom';
import image1 from './assets/campaign_1.png'; // 첫 번째 이미지 파일
import image2 from './assets/campaign_1-1.png'; // 두 번째 이미지 파일
import image3 from './assets/campaign_1-2.png'; // 두 번째 이미지 파일
import image4 from './assets/campaign_3.png';
import playing from './assets/playing_loh_final.gif';
import logo from "./assets/Logo_heimland.png";

const LegionsOfHeimland = () => {
  return (
    <div className="min-h-screen bg-gray-100 flex flex-col">
      {/* Navbar */}

      {/* Page Content */}
      <main className="flex-grow mt-20 pt-16"> {/* pt-16 to offset the fixed navbar */}
        
      <div className="w-full flex justify-center mb-8">
          <img
            src={logo}
            alt="First Image"
            className="w-full sm:w-1/2 md:w-2/5 max-w-full object-cover"
          />
        </div>
        {/* Top YouTube Video */}
        <div className="w-full flex justify-center mb-8">
        <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/3AhBNdxrISA?si=RtcqhK7q6NQpa1d7" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </div>

        {/* First Image */}
        <div className="w-full flex justify-center mb-8">
          <img
            src={image1}
            alt="First Image"
            className="w-full sm:w-1/2 md:w-2/5 max-w-full object-cover"
          />
        </div>
        
        <div className="w-full flex justify-center">
          <img
            src={image4}
            alt="Second Image"
            className="w-full sm:w-1/2 md:w-2/5 max-w-full object-cover"
          />
        </div>
        
        <div className="w-full flex justify-center mb-8">
        <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/cpW8B-ai1Tg?si=haTsEw7WuafcVt8g" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </div>

        {/* Second Image with Overlaid YouTube Videos */}
        <div className="w-full flex justify-center">
          <img
            src={image2}
            alt="Second Image"
            className="w-full sm:w-1/2 md:w-2/5 max-w-full object-cover"
          />
        </div>


        <div className="w-full flex justify-center mt-0">
            <img
            src={playing}  // 첫 번째 GIF의 경로를 지정
            alt="Playing"
            className="w-full sm:w-1/2 md:w-2/5 max-w-full object-cover"
            />
        </div>
        <div className="w-full flex justify-center -mt-1">
            <img
              src={image3}
              alt="Second Image"
              className="w-full sm:w-1/2 md:w-2/5 max-w-full object-cover"
            />
        </div>
        <div className="w-full flex justify-center mb-8">
            <span className="font-extrabold text-4xl">We'll Open Our KS Campaign Soon!!</span>
        </div>
      </main>

      {/* Footer */}
      <footer className="bg-gray-800 text-white py-6">
        <div className="container mx-auto text-center">
          <p className="text-sm">© 2024 Blisshouse Studio. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
};

export default LegionsOfHeimland;
