// src/Carousel.js
import React, { useState, useEffect } from 'react';
import carousel1 from './assets/webimage.png';
import carousel2 from './assets/carousel2.jpg';
import carousel3 from './assets/carousel3.jpg';

const Carousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const images = [carousel1];

  useEffect(() => {
    // const interval = setInterval(() => {
    //   setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    // }, 3000);
    // return () => clearInterval(interval);
  }, [images.length]);

  return (
    <div className="relative w-full h-screen overflow-hidden">
      <div className="flex w-full h-full transition-transform ease-out duration-700" style={{ transform: `translateX(-${currentIndex * 100}%)` }}>
        {images.map((image, index) => (
          <div key={index} className="w-full h-full flex-shrink-0">
            <img src={image} alt={`Slide ${index + 1}`} className="w-full h-full object-cover" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Carousel;
