// src/Navbar.js
import React from 'react';
import { Link, useNavigate } from 'react-router-dom'; // useNavigate를 사용하여 페이지 이동
import { Link as ScrollLink, scroller } from 'react-scroll'; // react-scroll의 Link와 scroller 사용
import logo from './assets/bhlogo.png';

const Navbar = () => {
  const navigate = useNavigate();

  // 클릭 이벤트 핸들러
  const handleNavClick = (section) => {
    navigate('/'); // 먼저 Home 페이지로 이동
    setTimeout(() => {
      // 이동 후 스크롤링을 위한 타이머 설정
      scroller.scrollTo(section, {
        duration: 200,
        smooth: true,
        offset: -70, // 네비게이션 바 높이만큼 오프셋 적용 (필요에 따라 조절)
      });
    }, 100); // 페이지가 로드될 시간을 확보하기 위해 약간의 지연을 줍니다.
  };

  return (
    <header className="fixed w-full bg-white shadow-md z-50">
      <nav className="container mx-auto flex justify-between items-center py-4 px-6">
        <div className="text-2xl font-bold">
          <Link to="/">
            <img src={logo} alt="Logo" className="w-10 h-10" style={{ zIndex: 10 }} />
          </Link>
        </div>
        <div className="hidden md:flex space-x-6">
          <button onClick={() => handleNavClick('home')} className="text-gray-600 hover:text-blue-500 cursor-pointer">Home</button>
          <button onClick={() => handleNavClick('about')} className="text-gray-600 hover:text-blue-500 cursor-pointer">About</button>
          <button onClick={() => handleNavClick('product')} className="text-gray-600 hover:text-blue-500 cursor-pointer">Product</button>
          <button onClick={() => handleNavClick('contact')} className="text-gray-600 hover:text-blue-500 cursor-pointer">Contact Us</button>
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
